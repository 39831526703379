import Dropzone from "dropzone";
import { Controller } from "stimulus";
import { DirectUpload } from "@rails/activestorage";
import SparkMD5 from "spark-md5";
import {
  getMetaValue,
  toArray,
  findElement,
  removeElement,
  insertAfter
} from "helpers";
import dotenv from "dotenv";

// Load environment variables from .env file
dotenv.config();

export default class extends Controller {
  static targets = ["input"];
  fileSize = [];
  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false;
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  bindEvents() {
    this.dropZone.on("addedfile", file => {
      // Check file size
      const maxFileSizeMB = 256; // 256 MB
      const actualSize = file.size/(1024 * 1024)
      this.fileSize.push(parseInt(actualSize))
      console.log(this.fileSize);
      const hasSmallFile = this.fileSize.some(size => size < maxFileSizeMB);
      if (hasSmallFile) {
        this.dropZone.options.dictCancelUploadConfirmation = 
          "Are you sure you want to cancel the upload? By doing this, all of your other files whose size is less than 256 MB will be canceled.";
      } else {
        this.dropZone.options.dictCancelUploadConfirmation = 
          "Are you sure you want to cancel this upload?";
      }
      if (file.size > maxFileSizeMB * 1024 * 1024) {
        // Call handleLargeFileUpload for files larger than 256 MB
        handleLargeFileUpload(this, file);
      } else {
        setTimeout(() => {
          file.accepted && createDirectUploadController(this, file).start();
        }, 500);
      }
    });

    this.dropZone.on("removedfile", async file => {
      if (file.isCanceled) {
        // Skip the removedfile logic if the file was canceled
        return;
      }
    
      if (file.controller) {
        const selectChangeUrl = document.location.origin + `/remove_canceled_file.json`;
        fetch(selectChangeUrl, {
          headers: {
              'Content-Type': 'application/json; charset=utf-8',
              'X-CSRF-Token': document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
          },
          body: JSON.stringify({ response: file.controller.xhr.responseURL }),
          method: 'POST',
        })
        .then(response => response.json())
              .then((data) => {
                console.log(data.message);
        });
        removeElement(file.controller.hiddenInput);
      } else if (file.uploadUrl) {
        await deleteBlobFromAzure(file.uploadUrl);
        removeElement(file.hiddenInput);
      }
    });
    

    this.dropZone.on("canceled", file => {
      file.isCanceled = true;
      if (file.controller) {
        file.controller.xhr.abort();
        removeElement(file.controller.hiddenInput);
      } else if (file.xhr) {
        file.xhr.abort();
        removeElement(file.hiddenInput);
      }
    });
    
    
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-direct-upload-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 10;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 5120;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }

  bindProgressEvent(xhr, file) {
    xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event, file)
    );
  }

  uploadRequestDidProgress(event, file) {
    const progress = (event.loaded / event.total) * 100;
    findElement(
      file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading(file) {
    file.status = Dropzone.UPLOADING;
    this.dropZone.emit("processing", file);
  }

  emitDropzoneError(file, error) {
    file.status = Dropzone.ERROR;
    this.dropZone.emit("error", file, error);
    this.dropZone.emit("complete", file);
  }

  emitDropzoneSuccess(file) {
    file.status = Dropzone.SUCCESS;
    this.dropZone.emit("success", file);
    this.dropZone.emit("complete", file);
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", event =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoQueue: false
  });
}

async function handleLargeFileUpload(source, file) {
  try {
    // Calculate the checksum using SparkMD5
    const checksum = await calculateChecksum(file);

    // Fetch CSRF token from meta tag
    const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");
    console.log("File Uploaded successfully.")
    // Call the Rails backend to create the blob and get the key
    const blobResponse = await fetch('/active_storage_blobs', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        blob: {
          filename: file.name,
          byte_size: file.size,
          metadata: {}, // Add any metadata you need
          checksum,
          content_type: file.type
        }
      }),
      credentials: 'include' // Ensure cookies are sent with the request
    });
    
    const blobResponseData = await blobResponse.json();
    const { key: blobName, signed_id: signedID } = blobResponseData;

    // Use the correct reference to create the hidden input and attach it to the file to remove/cancel later
    file.hiddenInput = document.createElement("input");
    file.hiddenInput.type = "hidden";
    file.hiddenInput.name = source.inputTarget.name;
    insertAfter(file.hiddenInput, source.inputTarget);
    source.hiddenInput = file.hiddenInput;
    console.log("Before Node JS API.")
    // Fetch upload URL
    const response = await fetch("https://dev-fileupload-node-app.apps.eyesol.net/upload-url", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken // Include CSRF token in headers
      },
      body: JSON.stringify({
        blobName
      })
    });

    const data = await response.json();
    const uploadUrl = data.uploadUrl;

    // Save upload URL to file object
    file.uploadUrl = uploadUrl;

    // Create a new XMLHttpRequest to manually upload the file
    const xhr = new XMLHttpRequest();
    file.xhr = xhr; // Attach xhr to file object
    xhr.open('PUT', uploadUrl, true);
    xhr.setRequestHeader('x-ms-blob-type', 'BlockBlob');

    // Bind progress events and emit upload events
    source.bindProgressEvent(xhr, file);
    source.emitDropzoneUploading(file);

    // Optionally add event listeners to monitor upload progress
    xhr.upload.addEventListener("progress", event => {
      const progress = (event.loaded / event.total) * 100;
    });

    xhr.onload = () => {
      if (xhr.status === 200 || xhr.status === 201) {
        file.hiddenInput.value = signedID;
        source.emitDropzoneSuccess(file);
      } else {
        console.error("File upload failed.");
        removeElement(file.hiddenInput);
        source.emitDropzoneError(file, xhr.statusText);
      }
    };

    xhr.onerror = () => {
      console.error("File upload error.");
      removeElement(file.hiddenInput);
      source.emitDropzoneError(file, xhr.statusText);
    };

    // Send the file
    xhr.send(file);

  } catch (error) {
    console.error("An error occurred during the file upload process:", error);
    if (file.hiddenInput) {
      removeElement(file.hiddenInput);
    }
    source.emitDropzoneError(file, error.message);
  }
}


async function calculateChecksum(file) {
  return new Promise((resolve, reject) => {
    const chunkSize = 2097152; // Read in chunks of 2MB
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();
    let cursor = 0; // current position in file

    fileReader.onerror = () => reject('MD5 calculation failed - error reading the file');
    fileReader.onload = (e) => {
      spark.append(e.target.result); // Append array buffer
      cursor += chunkSize; // Move to next chunk

      if (cursor < file.size) {
        fileReader.readAsArrayBuffer(file.slice(cursor, cursor + chunkSize));
      } else {
        resolve(spark.end()); // Complete
      }
    };

    fileReader.readAsArrayBuffer(file.slice(0, chunkSize)); // Start reading
  });
}

async function deleteBlobFromAzure(uploadUrl) {
  try {
    // Send DELETE request to the Azure Blob Storage
    const response = await fetch(uploadUrl, {
      method: 'DELETE',
      headers: {
        'x-ms-blob-type': 'BlockBlob'
      }
    });

    if (!response.ok) {
      throw new Error(`Failed to delete blob: ${response.statusText}`);
    }
  } catch (error) {
    console.error('Error deleting blob:', error);
  }
}
